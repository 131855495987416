import useSWR from "swr";
import { URLS } from "../../../../services/urls";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../../components/utils/functions";


const groupedWallets = (wallets) => {
    // Group by 'mobile' and sum 'amount'
    const groupedData = wallets.reduce((acc, wallet) => {
      const mobile = wallet.mobile;
  
      // If the mobile exists in the accumulator, sum the amounts
      if (acc[mobile]) {
        acc[mobile] += wallet.amount;
      } else {
        // Initialize with the wallet's amount
        acc[mobile] = wallet.amount;
      }
  
      return acc;
    }, {});

    return groupedData;
}

const AutresCaisses = () => {

  const {
    data: wallets,
    isLoading: loadingWallets,
    mutate: mutateMP,
  } = useSWR(URLS.WALLET.MOBILE.list(""));
  

    return (
        <div>
            <div className="card p-3">
                <div className="card-body">
                    <h4 className='text-uppercase'>Autres caisses</h4>
                </div>
                <div className="row">

                {Object.entries(groupedWallets(wallets?.results ?? [])).map(([mobile, amount]) => (
                    <div className="col-md-3">

                        <div
                        key={'l' + mobile.id}
                        className={`card mb-2 border-1 wallet-card `}
                        >
                        <Link className="card-body">
                            <div className="d-flex align-items-center">
                            <div className="avatar-md flex-shrink-0">
                                <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                                <span className="uim-svg">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="1em"
                                    >
                                    <path
                                        className="uim-quaternary"
                                        d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                                    ></path>
                                    <path
                                        className="uim-tertiary"
                                        d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                    ></path>
                                    <path
                                        className="uim-primary"
                                        d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                    ></path>
                                    </svg>
                                </span>
                                </span>
                            </div>
                            <div className="flex-grow-1 overflow-hidden ms-4">
                                <p className="text-muted text-truncate font-size-15 mb-2 text-uppercase">
                                {' '}
                                Solde{' '} {mobile} 
                                </p>
                                <h3 className="fs-4 flex-grow-1 mb-3">
                                {formatNumber(amount)} <span className="text-muted font-size-16">FCFA</span>
                                </h3>
                            </div>
                            <div className="flex-shrink-0 align-self-start">
                            </div>
                            </div>
                        </Link>
                        </div>
                    </div>
                  ))}
                </div>
            </div>
        </div>
    )
}

export default AutresCaisses;