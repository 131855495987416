import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import Select from 'react-select';
import moment from 'moment';
import { URLS } from '../../../services/urls';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TYPES_OF_BUY, TYPES_OF_BUY_COFFRE, colourStyles } from '../../../components/utils/consts';
import { API } from '../../../services/axios';
import CLoader from '../../../components/Loader';

const expenseType = [{ label: '', value: '' }];
const securedBoxTypes = ["TRANSFERT_DE_FONDS", "TRANSFERT_ET_DEPLACEMENT"]

const TransfertToMainForm = ({ wallet, onSuccess, handleClose, }) => {
  const [options, setOptions] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchUsers, setSearchUsers] = useState('');
  const [selectedUser, setSelectedUser] = useState();
  const [searchSupplier, setSearchSupplier] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedReason, setSelectedReason] = useState();

  const { auth } = useSelector((state) => state.auth);
  const { active_agency } = useSelector((state) => state.agencies);


  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSumbit = (data) => {

    const toSend = {
        "wallet": wallet?.id,
        "amount": data?.amount,
        "type": "WITHDRAWAL",
        "sortie_caisse": true,
        "transfert_to_secured_box": false,
        "trannsport_amount": data?.mobileFees ?? 0.0,
        "reason": "TRANFERT_TO_MAIN_CHECKOUT",
        "beneficiary_name": active_agency?.name,
    }
        setLoading(true)
        API.post(URLS.TRANSFERT.create, toSend).then((resp) => {
            toast.success("LA sortie de caisse a bien été éffectuéé")
            if(onSuccess){
                onSuccess()
            }
            if(handleClose){
                handleClose()
            }
            setSearchSupplier()
            setSearchUsers()
            setSearchSupplier("")
            setSearchUsers("")
            setLoading(false)
            reset()
        }).catch((error) => {
            setLoading(false)
            toast.error(error?.response?.data?.detail ?? "Une erreur s'est produite, veuiller reessayer!")
        })

  }

  useEffect(() => {
    setOptions([...TYPES_OF_BUY?.map((expense) => {
      return { value: expense, label: `${expense.label}` };
    })])
  }, [])
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Nouveau transfert ({wallet?.amount} FCFA disponibles)</h4>

          {/* <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#home1"
                role="tab"
                aria-selected="true"
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Remise à un collaborateur</span>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#profile1"
                role="tab"
                aria-selected="false"
                tabindex="-1"
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">Versement banque</span>
              </a>
            </li>
          </ul> */}

          <div className="tab-content text-muted">
            <div className="tab-pane active show" id="home1" role="tabpanel">
              <form onSubmit={handleSubmit(onSumbit)}>
                <div className="row">
                  {/* <div className="col-md-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="expenseType">Type d’achat</label>
                      <Select
                          className="basic-single2 text-black"
                          classNamePrefix="select"
                          value={selectedReason}
                          onChange={(newValue) => setSelectedReason(newValue)}
                          
                          name="prestation"
                          placeholder={'Sélectionner un type d’achat'}
                          options={
                            [
                                {
                                    label: "Transfert en compte",
                                    value: "TRANFERT_TO_MAIN_CHECKOUT"
                                }
                            ]
                          }
                          styles={colourStyles}
                        />
                    </div>
                  </div> */}
                  
                  <div className="col-md-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="#">Montant {watch("reason") === "TRANSFERT_ET_DEPLACEMENT" && "(Transport exclus)"} </label>
                      <input className="form-control" type="number" {...register("amount", {required: true, max: wallet?.amount ?? 0})} placeholder="Entrer le Montant" />
                    </div>
                  </div>
                  {selectedReason?.value !== "TRANFERT_TO_MAIN_CHECKOUT" && <div className="col-md-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="#">Montant des frais</label>
                      <input className="form-control" type="number" {...register("mobileFees", {required: true})} placeholder="Montant des frais" />
                    </div>
                  </div>}
                  {/* 
                  {selectedReason?.value !== "TRANFERT_TO_MAIN_CHECKOUT" && <div className="col-md-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="#">Bénéficiaire</label>
                      <input className="form-control" type="text" {...register("beneficiaryName", {required: true})} placeholder="Entrer le Bénéficiaire" />
                    </div>
                  </div>}
                   */}
                  {
                    watch("reason") === "TRANSFERT_ET_DEPLACEMENT" &&
                    <div className="col-md-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="#">Cout du transport</label>
                      <input className="form-control" type="number" {...register("transportAmount", {required: true, max: wallet?.amount ?? 0})} placeholder="1000" />
                    </div>
                  </div>
                  }
                  <div className="col-12">
                    <div className="mb-3">
                        <div>
                            <button className="btn-primary btn px-4 waves-effect waves-light d-flex">
                                
                                <span className="my-auto">Valider</span>{' '}
                                <span className="ms-2 d-inline-flex">
                                {' '}
                                <CLoader size={20} show={loading} />{' '}
                                </span>
                            </button>
                        </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="tab-pane" id="profile1" role="tabpanel">
              <p className="mb-0">
                Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee
                squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes
                anderson artisan four loko farm-to-table craft beer twee. Qui photo booth
                letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransfertToMainForm;
