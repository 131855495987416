import { Link } from "react-router-dom";

const ClosedCheckoutPage = () => {
  return (
    <div>
      <div className="auth-maintenance d-flex align-items-center min-vh-100">
        <div className="bg-overlay bg-light" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="auth-full-page-content d-flex min-vh-100 py-sm-5 py-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-3">
                  <div className="text-center mb-4">
                    <Link to="" className="">
                      <img
                        src="/assets/images/logo-dark.webp"
                        alt=""
                        height="80"
                        className="auth-logo logo-dark mx-auto remove-bg"
                      />
                      <img
                        src="/assets/images/logo-dark.webp"
                        alt=""
                        height="80"
                        className="auth-logo logo-light mx-auto"
                      />
                    </Link> 
                    <p className="text-muted mt-2">
                      Services de beauté et Spa
                    </p>
                  </div>
                    <div className="card my-auto overflow-hidden" style={{minHeight: "500px"}}>
                      <div className="row g-0 h-100">
                        <div className="col-lg-6">
                          <div className="bg-overlay bg-primary" />
                          <div className="h-100 bg-auth align-items-end"></div>
                        </div>
                        <div className="col-lg-6">
                          <div className="p-lg-5 p-4 h-100 d-flex flex-column justify-content-center">
                            <div>
                              <div className="text-center mt-1">
                                <h4 className="font-size-18">La caisse a été fermée</h4>
                                <p className="text-muted">
                                  La reouverture se fera automatiquement à 7:00 le jour suivant la fermeture.
                                </p>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                    <div className="mt-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} GF. Crafted
                      with <i className="mdi mdi-heart text-danger"></i> by  <a href="em-sarl.com" target="_blank">Em-Sarl</a>
                    </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
      </div>
    </div>
  );
};

export default ClosedCheckoutPage;
