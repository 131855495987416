import './App.css';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from './store/store';
import Routes from './components/routes';
import ConfigSWR from './services/swr';
import Auth from './components/auth';
import 'react-toastify/dist/ReactToastify.css';
import './styles/rsuite.css';
import './translations/translations';
import DisconnectInactiveUser from './components/auth/DisconnectInactiveUser';
import CloseCheckout from './components/auth/CloseCheckout';


function App() {
  return (
      <Provider store={store}>
        <ConfigSWR>
          <Auth/>
          <DisconnectInactiveUser />
          {/* Close checkout */}
          {/* <CloseCheckout /> */}
          <Routes />
          <ToastContainer />
        </ConfigSWR>
      </Provider>
  );
}

export default App;
