import useSWR from "swr";
import { URLS } from "../../../services/urls";
import { formatNumber } from "../../../components/utils/functions";


const formatDate = (date) => {
    if (date) {
      return String(date.toLocaleDateString()).split('/').reverse().join('-');
    } else {
      return '9999-01-01';
    }
  };

  
const DailySummary = ({ employee, dates }) => {

    const {
        data: stats,
        isLoading: loadingStats,
        mutate: refetchStats,
      } = useSWR(
        URLS.STATS.EMPLOYEE.list(employee.id, formatDate(dates?.checkin), formatDate(dates?.checkout))
      );
  return (
    <div>
      {/* <div className="bg-light p-3 my-3">
        <p className="text-uppercase mb-0 text-center">Totaux</p>
      </div> */}
      <div className="mb-2">
        <table className="table">
          <tbody>
            <tr className="text-uppercase">
              <td className="bg-light">Total des prestations</td>
              <td>
                {stats?.nbre_prestations}
              </td>
              <td className="bg-light">total des produits</td>
              <td>{formatNumber(stats?.nbre_produits)}</td>
            </tr>
            <tr className="text-uppercase">

            <td className="bg-light">Total ca</td>
              <td>
                {formatNumber(stats?.ca)}
              </td>
              <td className="bg-light">% de participation au CA</td>
              <td>{Number(stats?.ca_percentage).toFixed(2)}</td>
            </tr>
            <tr className="text-uppercase">
              <td className="bg-light">Salaire Fixe</td>
              <td>{formatNumber(parseFloat(employee.salary))}</td>
              <td className="bg-light text-danger">Avance sur salaire</td>
              <td className=" text-danger">{stats?.avance_salaire}</td>
            </tr>
            <tr className="text-uppercase">
              <td className="bg-light">Commissions</td>
              <td>
                {formatNumber(stats?.commission)}
              </td>
              <td className="bg-light text-danger">Retenue sur salaire</td>
              <td className=" text-danger">
                {stats?.retenue_salaire}
              </td>
            </tr>
            <tr className="text-uppercase">
                <td className="bg-light">Primes EXCEPTIONNELLES</td>
                <td>{stats?.prime_exeptionnelle}</td>
                <td className="bg-light">Salaire Net</td>
                <td> {formatNumber(parseFloat(employee.salary) + stats?.commission - stats?.avance_salaire - stats?.retenue_salaire )} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DailySummary;
