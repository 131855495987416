import moment from 'moment/moment';
import { useEffect, useRef, useState, useTransition } from 'react';
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import { confirmDeletion } from '../../../components/dletion';
import { URLS } from '../../../services/urls';
import { OTHERS_TRANSACTIONNS_TYPES } from '../../../components/utils/consts';
import useSWR from 'swr';
import LoaderPage from '../../../components/LoaderPage';

const TransactionTicket = ({ invoice, handleClose }) => {

  const [dimensions, setDimensions] = useState({ width: 100, height: 500 });
  // const { data: inv, isLoading: loadingInvoice } = useSWR(URLS.TRANSFERT.details(invoice?.id));
  const inv = invoice;
  const loadingInvoice = false;

  const ticketRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
            @page {
              size: ${dimensions.width}mm ${dimensions.height}mm;
              margin: 0;
            }
          }`,
    content: () => ticketRef?.current,
    documentTitle: 'Bon-de-caisse-N-' + inv?.reference,
    // onBeforeGetContent: () => setHideButton(true),
    // onAfterPrint: () => setHideButton(true)
  });

  const { t } = useTranslation();

  const handleDelete = (event) => {
    event.preventDefault();
    confirmDeletion(URLS.TRANSFERT.delete(inv.id), t, handleClose);
  };

  useEffect(() => {
    var elem = document.getElementById('transactionTicket');
    if (elem) {
      var rect = elem.getBoundingClientRect();
      setDimensions((prev) => {
        return {
          height: rect.width * 0.4645833,
          width: 90,
        };
      });
    }
  }, []);
  return (
    <div className='transaction-ticket'>
      <div className="row">
        <div className="col-md-2 col-2"></div>
        <div className="col-md-8 col-8 text-center">
          <div>
            {loadingInvoice && <LoaderPage />}
          </div>
          {!loadingInvoice &&<div>
            <div className="mb-3 border-md-3 border p-3" ref={ticketRef} id="transactionTicket">
              <div className="row">
                <div className="col-12">
                    <div className='text-center'>
                      <img
                        src="/assets/images/logo-dark.webp"
                        alt=""
                        height="100"
                        className=" mx-auto"
                      /> <br />
                      <p className="text-uppercase fw-bold text-center">{inv?.agency?.name}</p>
                      <p className="small mb-0 text-center">INSTITUT DE BIEN - ETRE - COIFFURES - SOINS CORPORELS - MASSAGE - ESPACE DETENTE</p>
                      <p className="small mb-0 text-center"><strong>RCCM</strong>: RC/DLN/2021/B/652 <strong>NIU</strong>: M022118505410B </p>
                        {/* <p className="mb-1 text-center">
                        <strong>Agence: </strong> {inv?.agency?.name} <br /> 
                        </p> */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="">
                      
                      <p className="mb-0 text-center small">
                      {!String(inv?.reference).includes("BS-") ? <strong>Caissier: </strong> : <strong>Donneur d'ordre: </strong>
}
                        {inv?.created_by?.first_name} {inv?.created_by?.last_name}
                      </p>
                    </div>
                  </div>
                  <div className="col-12  text-center">
                    <div>
                      <p className="mb-0 small text-center"> <strong>Date:</strong> <span>{moment(inv?.created_at).format('DD/MM/YYYY')}</span> <span className="ms-3">{moment(inv?.created_at).format('HH:mm')}</span> </p>
                      {/* <p className="mb-1 text-center">
                        <strong>Code Facture: </strong> {inv?.reference}
                        </p> */}
                    </div>
                    <br />
                      <h4 className='text-uppercase text-black'>{String(inv?.reference).includes("AC-") ? "Approvionnement de compte" : "Bon de sortie"}</h4>
                    <hr style={{borderTop: "dotted 2px"}} className='my-2' />
                  </div>
                <div className="col-12  text-center">
                  <div>
                    {/* <p className="mb-1 text-center">
                      <strong>Code: </strong> {inv?.reference}
                    </p> */}
                    <p className="mb-1 text-center">
                      
                      {/* <strong>Caissier: </strong> {inv?.created_by?.first_name}{' '}
                      {inv?.created_by?.last_name} */}
                    </p>
                  </div>
                  
                    {inv?.type === 'DEPOSIT' && (
                      <>
                        <hr style={{ borderTop: 'dotted 2px' }} />
                        <div className="d-flex py-1">
                          <span>
                            <strong>Nom du Client</strong>
                          </span>
                          <span className="ms-auto">{inv?.beneficiary_name}</span>
                        </div>
                      </>
                    )}

                  {inv?.type !== 'DEPOSIT' && (
                    <>
                        <hr style={{ borderTop: 'dotted 2px' }} className="my-0"  />
                        <div className="d-flex py-1">
                          <span>
                            <strong>Nom du Bénéficiaire</strong>
                          </span>
                          <span className="ms-auto">{inv?.beneficiary_name}</span>
                        </div>
                      </>
                    )}
                    <hr style={{ borderTop: 'dotted 2px' }} className="my-0"/>
                  <div className='py-1'>
                    <div className="d-flex">
                      <span>
                        <strong>Motif</strong>
                      </span>
                      {
                        OTHERS_TRANSACTIONNS_TYPES.includes(inv?.reason) ? 
                        <span className="ms-auto">{String(inv?.custom_reason).toUpperCase()}</span>
                        :

                      <span className="ms-auto">
                        {inv?.designation
                          ? inv?.designation
                          : String(inv?.reason === "TRANSFERT_ET_DEPLACEMENT" ? "TRANSPORT_ET_DEPLACEMENT" : String(inv?.reason === "CUSTOM_REASON" ? inv?.custom_reason : inv?.reason)).toUpperCase().replaceAll('_', ' ')}
                      </span>
                      }
                    </div>
                  </div>
                  <hr style={{ borderTop: 'dotted 2px' }} className="my-0" />
                  <div className="py-1">
                    <div className="d-flex">
                      <span>
                        <strong>Montant</strong>
                      </span>
                      <span className="ms-auto">{inv?.amount} FCFA</span>
                    </div>
                  </div>
                  {/* {inv?.type !== 'DEPOSIT' && <><hr style={{ borderTop: 'dotted 2px' }} className="my-0" />
                  <div className="py-1">
                    <div className="d-flex">
                      <span className='mb-3'>
                        <strong>Signatures</strong>
                      </span>
                      <span className="ms-auto"></span>
                      
                    </div>
                    <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Caisse ({inv?.created_by?.first_name}{' '}
                              {inv?.created_by?.last_name}) </th>
                            <th scope="col">Bénéficiaire ({inv?.beneficiary_name})</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span style={{height: '60px', display: 'block'}}></span>
                            </td>
                            <td>
                            <span style={{height: '60px', display: 'block'}}></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </div></>} */}
                  <hr style={{ borderTop: 'dotted 2px' }} className="my-0"  />
                  <div className="row mt-4">
                    <div className="col-md-4 mt-2">
                      <p className='fw-bold text-underline'>Signature Caisse</p>
                    </div>
                    <div className="col-md-4">
                      <div>
                      <Barcode height={50} value={inv?.reference} width={1} />
                    </div>
                    </div>
                    <div className="col-md-4 mt-2">
                      { !String(inv?.reference).includes("AC-") && <p className='fw-bold text-underline'>Bénéficiaire</p>}
                    </div>
                  </div>
                  
                  <div className="d-flex">
                    <div className="ms-auto">
                      <span className="small">Imprimé le: {moment().format("DD/MM/YYYY")} <span className="ms-2">{moment().format("HH:MM")}</span> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light rounded-0"
                onClick={handlePrint}
              >
                {' '}
                Imprimer le Coupon
              </button>
              {/* <button
                type="button"
                className="btn btn-danger ms-2 waves-effect waves-light rounded-0"
                onClick={handleDelete}
              >
                {' '}
                Supprimer
              </button> */}
            </div>
          </div>}
        </div>
        <div className="col-md-2 col-2"></div>
      </div>
    </div>
  );
};

export default TransactionTicket;
