import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import WithdrawForm from './WithdrawForm';
import { loadAgencies } from '../../../store/actions/agencies';
import { URLS } from '../../../services/urls';
import useSWR from 'swr';
import moment from 'moment';
import { DEFAULT_PAGE_COUNT } from '../../../components/utils/consts';
import PageTitle from '../../../components/PageTitle';
import GlobalCoffre from './Global/Coffre';

const CheckoutList = () => {
  const [activeWallet, setActiveWallet] = useState();
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const [selectedtTransaction, setSelectedtTransaction] = useState();

  const { agencies, active_agency } = useSelector((state) => state.agencies);

  const { data: transferts, isLoading: loadingTransferts } = useSWR(
    URLS.TRANSFERT.list(active_agency?.id, '', page, `&wallet__id=${activeWallet?.id ?? ''}`)
  );

  const { data: walletsAdmin, isLoading: loadingWalletsAdmin, mutate } = useSWR(URLS.WALLET.admin(active_agency?.id));

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const dispatch = useDispatch();

  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const hasRigths = true;

  const handleSuccess = () => {
    setToEdit();
    if (hasRigths) {
      mutate();
    }
    dispatch(loadAgencies());
  };

  const handleWithdraw = (wallet) => {
    setActiveWallet(wallet);
    window.$('.new-invoice-modal')?.modal('show');
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Coffre'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Coffre
            </li>
          </ol>
          }
        />
      </div>
      
      {active_agency?.id === '' ? <GlobalCoffre /> : <div className="col-12">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="card p-3">
              <div className="card-body">
                <h4 className='text-uppercas'>Coffre (s)</h4>
                <div className="pt-2">
                  {walletsAdmin?.results?.map((wallet) => (
                    <div
                      key={'l' + wallet.id}
                      className={`card mb-2 border-1 wallet-card ${
                        activeWallet?.id === wallet?.id && 'active'
                      }`}
                    >
                      <Link className="card-body" onClick={() => setActiveWallet(wallet)}>
                        <div className="d-flex align-items-center">
                          <div className="avatar-md flex-shrink-0">
                            <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                              <span className="uim-svg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="1em"
                                >
                                  <path
                                    className="uim-quaternary"
                                    d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                                  ></path>
                                  <path
                                    className="uim-tertiary"
                                    d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                  ></path>
                                  <path
                                    className="uim-primary"
                                    d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                  ></path>
                                </svg>
                              </span>
                            </span>
                          </div>
                          <div className="flex-grow-1 overflow-hidden ms-4">
                            <p className="text-muted text-truncate font-size-15 mb-2">
                              {' '}
                              Solde{' '}
                              <span className="badge rounded badge-soft-success font-size-12">
                                Coffre
                              </span>
                            </p>
                            <h3 className="fs-4 flex-grow-1 mb-3">
                              {wallet?.amount} <span className="text-muted font-size-16">FCFA</span>
                            </h3>
                            {/* <p className="text-muted mb-0 text-truncate">
                              <span className="badge bg-subtle-danger text-danger font-size-12 fw-normal me-1">
                                <i className="mdi mdi-arrow-bottom-left"></i> 7.8% Loss
                              </span>{' '}
                              vs last month
                            </p> */}
                          </div>
                          <div className="flex-shrink-0 align-self-start">
                            <button
                              onClick={() => handleWithdraw(wallet)}
                              className="btn btn-primary text-uppercase rounded-0">
                                + Sortie
                              </button>
                            {/* <div className="dropdown">
                              <a
                                className="dropdown-toggle btn-icon border rounded-circle"
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="ri-more-2-fill text-muted font-size-16"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={() => handleWithdraw(wallet)}
                                >
                                  Sortie de caisse
                                </Link>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {/* {agencies?.map((agency) => (
                    <div
                      key={'l' + agency.id}
                      className={`card mb-2 border-1 wallet-card ${
                        activeWallet?.id === agency?.wallet?.id && 'active'
                      }`}
                    >
                      <Link className="card-body" onClick={() => setActiveWallet(agency.wallet)}>
                        <div className="d-flex align-items-center">
                          <div className="avatar-md flex-shrink-0">
                            <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                              <span className="uim-svg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="1em"
                                >
                                  <path
                                    className="uim-quaternary"
                                    d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                                  ></path>
                                  <path
                                    className="uim-tertiary"
                                    d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                  ></path>
                                  <path
                                    className="uim-primary"
                                    d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                  ></path>
                                </svg>
                              </span>
                            </span>
                          </div>
                          <div className="flex-grow-1 overflow-hidden ms-4">
                            <p className="text-muted text-truncate font-size-15 mb-2"> Solde</p>
                            <h3 className="fs-4 flex-grow-1 mb-3">
                              {agency?.wallet?.amount}{' '}
                              <span className="text-muted font-size-16">FCFA</span>
                            </h3>
                          </div>
                          <div className="flex-shrink-0 align-self-start">
                            <div className="dropdown">
                              <a
                                className="dropdown-toggle btn-icon border rounded-circle"
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="ri-more-2-fill text-muted font-size-16"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={() => handleWithdraw(agency?.wallet)}
                                >
                                  Sortie de caisse
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
            <div
              className="modal fade new-invoice-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="myLargeModalLabel">
                      {/* {toEdit ? 'Modifitation de la sortie de caisse' : 'Sortie de caisse'} */}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <WithdrawForm
                      handleClose={handleClose}
                      onSuccess={handleSuccess}
                      toEdit={toEdit}
                      wallet={activeWallet}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12">
          <div className="card">
            <div className="card-header border-0 align-items-center d-flex pb-0">
              <h4 className=" mb-0 text-uppercas flex-grow-1">Suivi du coffre</h4>
            </div>
            <div className="card-body pt-2">
            <div className="">
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0 table-bordered">
                      <thead className='table-light'>
                        <tr>
                          <th className='text-uppercas'>Date</th>
                          <th className='text-uppercas'>Référence</th>
                          <th className='text-uppercas'>Libellé</th>
                          <th className='text-uppercas'>Encaissements</th>
                          <th className='text-uppercas'>Décaissements</th>
                          {/* <th>Solde</th> */}
                          {/* <th></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        
                        { activeWallet && transferts?.results?.map((transfert) => (
                          <tr key={'trsc-' + transfert.id} className={`badge-soft-${transfert?.type === "DEPOSIT" ? 'success' : 'danger'}`}>
                            
                            <td>
                              {moment(transfert?.created_at).format('DD/MM/YYYY HH:MM')}
                            </td>
                            <td>
                            { transfert?.designation ? transfert?.designation : "" + transfert?.reference} 
                            {transfert?.beneficiary_name && ` (${(transfert?.beneficiary_name ?? "")})`}
                            </td>
                            <td>
                              {String(transfert.reason === "CUSTOM_REASON" ? transfert.custom_reason : transfert.reason).toUpperCase().replaceAll('_', ' ')}
                            </td>
                            <td>
                              {
                                transfert?.type === 'DEPOSIT' ? (transfert?.amount + " FCFA") : ""
                              }
                              {
                                transfert?.reason === "APPROVISIONNEMENT_DE_COMPTE" && <span class="badge badge-soft-success ms-2 p-2">{transfert.payment_method}</span>
                              }
                            </td>
                            <td>
                              {
                                transfert?.type === 'DEPOSIT' ? "" : (transfert?.amount + " FCFA")
                              }
                            </td>
                            {/* <td>
                              <p className="mb-0"> 
                                {parseFloat(transfert.new_sold ?? '0')}{' '}
                                FCFA
                              </p>
                            </td> */}
                            
                            {/* <td>
                            <div className="d-flex gap-3">
                              <a href="javascript:void(0);" className="btn btn-success btn-sm">
                                <i className="mdi mdi-pencil"></i>
                              </a>
                              <a href="javascript:void(0);" className="btn btn-danger btn-sm">
                                <i className="mdi mdi-delete"></i>
                              </a>
                            </div>
                          </td> */}
                          <td>
                            {transfert?.type === 'DEPOSIT' ? "" : <Link 

            data-bs-toggle="modal"
            data-bs-target=".details-trans-modal"
                            to={"#"} onClick={() => setSelectedtTransaction((transfert))}>
                              <i className='mdi mdi-cloud-print-outline'></i>
                            </Link>} 
                          </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    
                  </div>
                </div>
            </div>
          </div>
            {/* <div className="card p-3">
              <div className="card-body">
                <h4>Historique des transactions</h4>
                <div className="pt-2">
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: '20px' }}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customCheck1"
                              />
                              <label className="form-check-label" for="customCheck1">
                                &nbsp;
                              </label>
                            </div>
                          </th>
                          <th>ID</th>
                          <th>Type</th>
                          <th>Montant</th>
                          <th>Motif</th>
                          <th>Bénéficiaire</th>
                          {/* <th>Mode de paiement</th> *
                          <th>Date</th>
                          <th>Statut</th>
                          {/* <th>Action</th> *
                        </tr>
                      </thead>
                      <tbody>
                        {transferts?.results?.map((transfert) => (
                          <tr key={'tr-' + transfert.id}>
                            <td>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheck2"
                                />
                                <label className="form-check-label" for="customCheck2">
                                  &nbsp;
                                </label>
                              </div>
                            </td>
                            <td>
                              <a href="javascript: void(0);" className="text-body">
                                #{transfert.reference}
                              </a>{' '}
                            </td>
                            <td>
                              {transfert?.type === 'DEPOSIT' ? (
                                <span className="badge rounded badge-soft-success font-size-12">
                                  Entrée
                                </span>
                              ) : (
                                <span className="badge rounded badge-soft-danger font-size-12">
                                  Sortie
                                </span>
                              )}
                            </td>
                            <td>
                              <p className="mb-0">
                                {transfert?.amount + parseFloat(transfert.trannsport_amount ?? '0')}{' '}
                                FCFA
                              </p>
                            </td>
                            <td>{String(transfert.reason).replaceAll('_', ' ')}</td>
                            <td>
                              {transfert.transfert_to_secured_box
                                ? 'Coffre'
                                : transfert.type === 'DEPOSIT'
                                ? activeWallet?.agency?.name || 'Agence'
                                : transfert.supplier
                                ? transfert.supplier?.name
                                : `${transfert?.beneficiary?.last_name} ${transfert.beneficiary?.last_name}`}
                            </td>
                            {/* <td>{transfert.payment_method}</td> *
                            <td>{moment(transfert?.created_at).format('lll')}</td>
                            {/* <td>
                            <i className="fab fa-cc-mastercard me-1"></i> Mastercard
                          </td> *
                            <td>
                              <span className="badge rounded badge-soft-success font-size-12">
                                Complété
                              </span>
                            </td>
                            {/* <td>
                            <div className="d-flex gap-3">
                              <a href="javascript:void(0);" className="btn btn-success btn-sm">
                                <i className="mdi mdi-pencil"></i>
                              </a>
                              <a href="javascript:void(0);" className="btn btn-danger btn-sm">
                                <i className="mdi mdi-delete"></i>
                              </a>
                            </div>
                          </td> *
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    {transferts?.count > 0 && (
                      <div className="paginations pt-3">
                        {/* <nav aria-label="...">
                                                    <ul class="pagination pagination-rounded">
                                                        <li class="page-item disabled">
                                                            <span class="page-link"><i class="mdi mdi-chevron-left"></i></span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                        <li class="page-item active">
                                                            <span class="page-link">
                                                                2
                                                                <span class="sr-only">(current)</span>
                                                            </span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"><i class="mdi mdi-chevron-right"></i></a>
                                                        </li>
                                                    </ul>
                                                </nav> *
                        <ul className="pagination pagination-rounded">
                          <li>
                            <Link
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, page - 1)}
                              className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                          {new Array(Math.ceil(transferts.count / DEFAULT_PAGE_COUNT))
                            .fill(3)
                            .map((pag, index) => (
                              <li
                                key={'page' + index}
                                className={`page-item ${page === index + 1 ? 'active' : ''}`}
                                aria-current="page"
                              >
                                <Link
                                  className={`pager-number ${
                                    page * DEFAULT_PAGE_COUNT === Math.ceil(transferts.count)
                                      ? 'disabled'
                                      : ''
                                  } ${page === index + 1 ? 'active' : ''}`}
                                  to="#"
                                  tabIndex={-1}
                                  onClick={(event) => handleChangePage(event, index + 1)}
                                >
                                  <span className="page-link">{index + 1}</span>
                                </Link>
                              </li>
                            ))}
                          <li>
                            <Link
                              tabIndex={-1}
                              className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>}
    </div>
  );
};

export default CheckoutList;
